<template>
  <v-dialog
    v-model="deleteTeamModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Tem certeza que deseja excluir o Time "{{team.name}}"?
        </div>
      </v-card-title>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            :disabled="saving"
            @click="deleteTeam()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Sim
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      team: {
        text: ''
      }
    }
  },
  computed: {
    ...mapState(['teams']),
    deleteTeamModal: {
      get () {
        return this.$store.state.deleteTeamModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteTeamModal',
          value: val
        })
      }
    }
  },
  watch: {
    deleteTeamModal (val) {
      if (val) {
        const team = this.teams.data.filter((item) => item._id === this.id)[0]
        this.team.name = team.name
      }
    }
  },
  methods: {
    deleteTeam () {
      this.saving = true
      this.$http.delete(`/teams/${this.id}`)
        .then(() => {
          this.saving = false
          this.deleteTeamModal = false
          this.$emit('deleted')
          this.$toast.success('Cadastro removido com sucesso.')
        })
        .catch((err) => {
          this.saving = false
          this.$toast.error(err.response.data.message)
        })
    }
  }
}
</script>
